<template>
  <div>
    <ContactoS1/>
  </div>
</template>
<script>
// @ is an alias to /src
import ContactoS1 from '@/components/Contacto/ContactoS1.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'Contacto',
  components: {
    ContactoS1
  },
  setup() {
    useMeta({
      title:'Estamos para ayudarte | Ibancar',
      meta:[
        {name:'robots', content:'index'},
        {name:'description', content:'¿Quieres contactar con Ibancar? Estamos para ayudarte por email, teléfono y WhatsApp. Ibancar México'},
        {name:'keywords', content:'Ibancar, contacto, ayuda, Préstamos por tu auto, empeña tu auto sin dejarlo préstamos sin importar buro de credito, préstamos personales, prestamos inmediatos, credito aval auto'}
      ],
      link: [
        { href: 'https://ibancar.com/contacto', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/contacto', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
}
</script>